import './Privacy.css';

function Privacy() {
  return (
    <div className="privacy page">
      <h2 className="privacy__title">Privacy</h2>
      <p>Wow!  Didn't really expect anyone to check this page.  Congrats on your diligence.</p>
      <p>I use any contact information you provide only to communicate to you about your project(s) or offerings/abilities I add/remove for the sole purpose of providing you quality results.  I will not sell your information gathered here, provide it to marketers, or talk to you about "exciting MLM opportunities".</p>
    </div>
  );
};

export default Privacy;
