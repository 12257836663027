import { Link } from 'react-router-dom';
import './Header.css';

import logo from './images/red-fringe-start-pose_150x150.jpg';

import Nav from './Nav';

function Header() {
  return (
    <header className="header">
      <div className="header__container">
        <Link className="header__link" to="/">
          <img className="header__logo" src={logo} alt="Liz in a dramatic post before starting a performance, in a custom wine-red skating dress with whirls of rhinestones, wine fringe along the bottom, and matching elbow length gloves"/>
        </Link>
        <h1 className="header__title">SwiftStitcher</h1>
        <span className="header__subtitle">Custom Skating Costumes, Corsetry, and More</span>
      </div>
      <Nav/>
    </header>
  );
}

export default Header;
