import './Bling.css';

function Bling() {
  return (
    <div className="bling page">
      <h2 className="bling__title">Rhinestoning</h2>
      <p>
        On the skating rink, on the dance floor, or in pictures, nothing adds sparkle like a little...sparkle. I can apply rhinestones to existing or custom outfits to let your brilliance shine.
      </p>
      <p>I can apply rhinestones of a variety of types to existing or custom garments, depending on material, purpose, and budget.</p>
      <p>Rhinestones create their best effect at a distance, so be aware that many projects involve <i>hundreds</i> of rhinestones.</p>
      <p>I charge extra for unusually small rhinestones, as they are extremely finicky and it takes many more of them to be effective.</p>
    </div>
  );
}

export default Bling;
