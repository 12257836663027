import { useState } from 'react';
import { Link } from 'react-router-dom';
import './Nav.css';

const links = {
  "/": "Home",
  custom: "Custom",
  alterations: "Alterations",
  bling: "Rhinestoning",
  pricing: "Pricing",
  // gallery: "Gallery",
  about: "About",
  contact: "Contact",
};

function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleClass = isOpen ? 'global-nav__list--open' : '';
  const navigate = () => {
    setIsOpen(false);
    window.scrollTo(0,0);
  };

  return (
    <>
      <button
        className="global-nav__toggle"
        onClick={ () => setIsOpen( isOpen => !isOpen ) }
        aria-label="Toggle Menu"
      >
        <i className="global-nav__toggle-icon"/>
      </button>
      <nav className={`global-nav`}>
        <ul className={`global-nav__list ${toggleClass}`}>
          { Object.entries(links).map( ([path, title]) => (
            <li className="global-nav__item" key={path}>
              <Link
                className="global-nav__link" to={path}
                onClick={ navigate }
              >
                {title}
            </Link>
            </li>
          )) }
        </ul>
      </nav>
    </>
  );
}

export default Nav;

