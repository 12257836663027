import { Link } from 'react-router-dom';

import './Alterations.css';

function Alterations() {
  return (
    <div className="alterations page">
      <h2 className="alterations__title">Alterations</h2>
      <p>Sometimes the difference between almost perfect and perfect is really important. Letting out a little, taking in a little, adding/removing/adjusting sleeves, or even swapping a part out can reintroduce delight.
    </p>
    <p>Below are some alteration services I offer, if you have a request not listed here please <Link to="/contact">contact me</Link>.</p>
      <ul className="alterations__list">
        <li>
          Removing/Adding/Replacing items such as Sleeves/Attached Skirts/Ruffles
        </li>
        <li>
          Adjusting Hems
        </li>
        <li>
          Letting out Seams (if there is insufficient seam allowance in the garment this may involve a more complicated addition of matching material)
        </li>
        <li>
          Taking in Seams
        </li>
      </ul>
    </div>
  );
}

export default Alterations;
