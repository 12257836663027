import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Base from './Base';
import Home from './Home';
import Custom from './Custom';
import Alterations from './Alterations';
import Bling from './Bling';
import Pricing from './Pricing';
import Gallery from './Gallery';
import About from './About';
import Contact from './Contact';
import Testimonials from './Testimonials';
import Privacy from './Privacy';

import './App.css';

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Base /> }>
            <Route index  element={ <Home /> }/>
            <Route path="custom" element={ <Custom /> }/>
            <Route path="alterations" element={ <Alterations /> }/>
            <Route path="bling" element={ <Bling /> }/>
            <Route path="pricing" element={ <Pricing /> }/>
            <Route path="gallery" element={ <Gallery /> }/>
            <Route path="about" element={ <About /> }/>
            <Route path="contact" element={ <Contact /> }/>
            <Route path="testimonials" element={ <Testimonials /> }/>
            <Route path="privacy" element={ <Privacy/> }/>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
