import './Contact.css';

function Contact() {
  return (
    <div className="contact page">
      <h2 className="contact__title">Contact Me</h2>
      <p>Email or text message are the best ways to reach me.  If this is the first time you've reached out on the medium, be sure to identify yourself so I can be clear about which project you are discussing!</p>
      <p>I try to respond as quickly as possible, but please understand that the vaguaries of life mean that I may not be able to respond immediately.</p>
      <ul>
        <li>Email: &lt;<a href="mailto:foiledyou@gmail.com">foiledyou@gmail.com</a>&gt;</li>
        <li>Text: <a href="sms:+18048735242">804-873-5242</a></li>
      </ul>
    </div>
  );
}

export default Contact;
