import { Link } from 'react-router-dom';

import './Footer.css';

          // <li className="footer-nav__item">
          //   <Link className="footer-nav__link" to="testimonials">Testimonials</Link>
          // </li>

function Footer() {
  return (
    <footer className="footer">
      <nav className="footer-nav">
        <ul className="footer-nav__list">
          <li className="footer-nav__item">
            <Link className="footer-nav__link" to="about">About</Link>
          </li>
          <li className="footer-nav__item">
            <Link className="footer-nav__link" to="privacy">Privacy</Link>
          </li>
          <li className="footer-nav__item">
            <Link className="footer-nav__link" to="contact">Contact</Link>
          </li>
        </ul>
      </nav>
      <p>
        <small className="footer__fine-print">Original costume designs copyright Frances "Liz" Ritter (&lt;<a href="mailto:foiledyou@gmail.com">foiledyou@gmail.com</a>&gt;), all other designs copyright their copyright owners</small>
      </p>
      <p>
        <small className="footer__fine-print">
          Photos copyright their respective creators, used with permission
        </small>
      </p>
      <p>
        <small className="footer__fine-print">Favicon and related images used under license from <a href="https://twemoji.twitter.com/">https://twemoji.twitter.com/</a>
        </small>
      </p>
    </footer>
  );
}

export default Footer;
