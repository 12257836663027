import { Link } from 'react-router-dom';

import lizFace from './images/liz-masked-face.png';

import './About.css';
    // <p>You can <Link to="/gallery">see samples of my work</Link>, <Link to="/testimonials">see what some satisfied customers have said</Link>, or <Link to="/contact">contact me about your project</Link>.  I look forward to working with you!</p>

function About() {
  return (
    <div className="about page">
      <h2 className="about__title">About Me</h2>
      <img className="about__pic" src={lizFace} alt="Liz Ritter's face, in a dramatic 'look at me!' post"/>I'm Liz Ritter, your favorite costumer!
      <p>
        I graduated from Virginia Commonwealth University (VCU) with a degree as a Costume Technician.  I won first place for a corset design and construction at the Southeaster Theatre Conference (SETC), have worked on multiple small theater and film productions, and my work was featured on the cover of Wild Heart magazine.
      </p>
      <p>In 2018 I began costuming figure skaters in Seattle and the surrounding areas.</p>
      <p>As of 2022, in addition to my sewing, design, and construction work I serve as Front Desk Manager at Sno-King Ice Arena in Snoqualmie, WA.</p>
      <p><Link to="/contact">Contact me about your project</Link>.  I look forward to working with you!</p>
    </div>
  );
}

export default About;
