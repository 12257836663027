import { Link } from 'react-router-dom';

import './Home.css';
import customPic from './images/black-gold-with-passing.png';
import rhinestonePic from './images/red-fringe-victory_250.png';
import alterationsPic from './images/stayin-alive-group_250.png';

const cards = [
  {
    heading: "Custom Creations",
    link: "custom",
    image: customPic,
    alt: "Liz in a black skating dress with a lacy gold applique on the chest and mesh sleeves that open into flowing cuffs at just below the elbow",
    blurb: "Custom design and construction allows for a look and fit tailored to you and your goals.  I have experience with custom skating costumes (individual, group, and team), corsetry, gowns, dresses, vests, and capes.",
  },
  {
    heading: "Rhinestoning",
    link: "bling",
    image: rhinestonePic,
    alt: "Liz on the ice with an arm raised, wearing a wine-colored dress.  The dress has on solid shoulder and straps that sweep together over the other shoulder. The dress has rhinestones roughly every three quarters of an inch all over it, along with highly rhinestoned flowers on one side with rhinestoned leaves extending halfway across it.  Rhinestoned elbow length gloves and matching mask, along with long fire-red fringe along the bottom of the skirt.",
    blurb: "On the skating rink, on the dance floor, or in pictures, nothing adds sparkle like a little...sparkle.  I can apply rhinestones to existing or custom outfits to let your brilliance shine.",
  },
  {
    heading: "Alterations",
    link: "alterations",
    image: alterationsPic,
    alt: "A group photo, two women and a man, from a 'Staying Alive' skate.  The women are in red sequin skate dresses trimmed in black with black fabric belts that tie in front with excess ends hanging down.  The man is in a red longsleeved shirt with red paisley print, and black slacks.",
    blurb: "Sometimes the difference between almost perfect and perfect is really important.  Letting out a little, taking in a little, adding, removing, adjusting sleeves, or even swapping a part out can reintroduce delight.",
  },
];

function Home() {
  return (
    <main className="home">
      <div className="card__container">
        { cards.map( card => (
          <div className="card" key={card.heading}>
            <h2 className="card__heading">{card.heading}</h2>
            <img className="card__image" src={card.image} alt="tmp"/>
            <div className="card__blurb">{card.blurb}
            </div>
            <Link to={card.link} className="card__button" aria-label="Read More about {card.heading}">{card.heading}</Link>
          </div>
        ) )}
      </div>
    </main>
  );
}

export default Home;
