import './Testimonials.css';

function Testimonials() {
  return (
    <div className="testimonials page">
      <h2>Testimonials</h2>
      FIXME:Testimonials
    </div>
  );
};

export default Testimonials;

