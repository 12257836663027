import { Link } from 'react-router-dom';

import './Gallery.css';


const pics = [
  {
    thumbnail: '//placekitten.com/50/50?image=1',
    pic: '//placekitten.com/600/300/?image=1',
    caption: 'Caption 1',
  },
  {
    thumbnail: '//placekitten.com/50/50?image=2',
    pic: '//placekitten.com/600/300/?image=2',
    caption: 'Caption 2',
  },
  {
    thumbnail: '//placekitten.com/50/50?image=3',
    pic: '//placekitten.com/600/300/?image=3',
    caption: 'Caption 3',
  },
  {
    thumbnail: '//placekitten.com/50/50?image=4',
    pic: '//placekitten.com/600/300/?image=4',
    caption: 'Caption 4',
  },
];

function Gallery() {
  return (
    <div className="gallery page">
      <h2>Gallery</h2>
      <ul className="gallery__list">
        { pics.map( (pic, index) => (
          <li key={pic.caption} className="gallery__item">
            <Link className="gallery__link" to={`/gallery/${index}`}>
              <img className="gallery__thumbnail" src={pic.thumbnail} alt={pic.caption}/>
            </Link>
            <p>{pic.caption}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Gallery;
