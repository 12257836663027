import './Custom.css';

function Custom() {
  return (
    <main className="custom page">
      <h2 className="custom__title">Custom Creations</h2>
      <p>
        Custom design and construction allows for a look and fit tailored to you and your goals.  I have experience with custom skating costumes (individual, group, and team), corsetry, gowns, dresses, vests, and capes.</p>

      <ul className="custom__list">
        <li>
          <b>Time:</b> Custom work involves multiple steps of deciding on on the look, construction, fabric, and accessories and ensuring that you are happy with the direction.  Please allow sufficient time for these steps, and be aware that I often have other projects, particularly during times of peak demand.  Specifics will vary wildly, so the earlier you check with me to get a base estimate, the less stress we will both encounter.
        </li>
        <li>
          <b>Materials:</b> Material has a significant impact on the quality, durability, construction difficult, and price - I can show you sample swatches and describe the impact to the end result, and can order the materials for you or you can provide your own if you have the materials.  I do not charge a fee for ordering materials and simply pass the costs on to you at-cost, though I may split shipping charges when ordering joint shipments.
        </li>
        <li>
          <b>Planning ahead:</b> I prefer to leave allowances for future alterations, however some designs and/or materials limit this option. I will share whenever that is a concern, and ask that if you anticipate a need for adjustments (such as a costume intended for several seasons with someone that could have a growth spurt) to share that so I can give you the best chance for lasting contentment.
        </li>
      </ul>
    </main>
  );
}

export default Custom;
