import { Link } from 'react-router-dom';

import './Pricing.css';

function Pricing() {
  return (
    <div className="pricing page">
      <h2 className="pricing__title">Pricing</h2>
      <p>
        I've been too busy working on projects to supply an explanation of my pricing to my web tech, so right now the best approach is
        to <Link to="/contact">contact me</Link> with a general description of your needs and I can either give a better answer or arrange a meeting to work out details.
      </p>
      <p>Also, remember that everyone shares the same skating season!</p>
    </div>
  );
}

export default Pricing;
